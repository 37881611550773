'use client';

import React from 'react';
import { getCookie } from 'cookies-next';
import { IconDepopIcon } from '@depop/web-ui-kit/Icons/IconDepopIcon';
/**
 * Just like our the layout file, all UI Kit css needs to be loaded in here
 * as it defines some important CSS layering orders which
 * need to be loaded first before our app specific globals
 */
import '@depop/web-ui-kit/styles.css';

import './globals.css';

import {
  getLogger,
  getUsefulErrorContents,
} from '@/modules/observability/logging.ts';
import { LANGUAGE_KEY } from '@/constants/cookies.ts';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';
import { Container } from '@/components/Container';

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function GlobalErrorBoundary({ error }: Props) {
  const language = getCookie(LANGUAGE_KEY);

  getLogger().error(
    `GlobalErrorBoundary caught an error`,
    getUsefulErrorContents(error, 'GlobalErrorBoundary'),
    error
  );

  recordMetric({
    type: 'increment',
    stat: Stdv1Stat['errors.errorBoundary.caught'],
    tags: ['boundaryType:globalErrorBoundary'],
  });

  return (
    <html lang={language}>
      <body>
        <main id="main">
          <Container className="container__global-error">
            <IconDepopIcon className="logo__depop-icon" title="Depop logo" />
            <h1 className="content__global-error">Something went wrong</h1>
          </Container>
        </main>
      </body>
    </html>
  );
}
